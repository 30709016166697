import { useEntity, useOptionalStore } from "@fscrypto/state-management";
import { dashboardFactory } from "../state/dashboard";

export const useDashboardEditor = ({ dashboardId }: { dashboardId: string }) => {
  const entity = useEntity(dashboardFactory, dashboardId);
  const store = useOptionalStore(entity?.editorStore);
  return {
    showWidgetPanel: store?.showWidgetPanel,
    showSettingsPanel: store?.showSettingsPanel,
    isGridActive: store?.isGridActive,
    activeCellId: store?.activeCellId,
    activeLayoutId: store?.activeLayoutId,
    isAddWidgets: store?.activeWidgetPanel === "add-widgets",
    isViewLayers: store?.activeWidgetPanel === "view-layers",
    activeConfigPanel: store?.activeConfigPanel ?? "settings",
    setActiveCellId: (id: string | null) =>
      entity?.updateEditorState({ activeCellId: id, activeConfigPanel: "settings" }),
    setActiveLayoutId: (id: string, preventActiveCell?: boolean) => {
      entity?.updateEditorState({
        activeLayoutId: id,
        activeCellId: preventActiveCell ? store?.activeCellId : id,
        activeConfigPanel: "settings",
      });
    },
    setActiveConfigPanel: (panel: "settings" | "styles") => entity?.updateEditorState({ activeConfigPanel: panel }),
    toggleGridActive: () => entity?.updateEditorState({ isGridActive: !store?.isGridActive }),
    setWidgetState: (state: WidgetState) => entity?.updateEditorState({ activeWidgetPanel: state }),
    closeWidgetPanel: () => entity?.updateEditorState({ showWidgetPanel: false }),
    openWidgetPanel: () => entity?.updateEditorState({ showWidgetPanel: true }),
    closeSettingsPanel: () => entity?.updateEditorState({ showSettingsPanel: false }),
    openSettingsPanel: () => entity?.updateEditorState({ showSettingsPanel: true }),
  };
};

export type DashboardEditorState = {
  activeCellId: string | null;
  activeLayoutId: string;
  isGridActive: boolean;
  activeWidgetPanel: WidgetState;
  activeConfigPanel: "settings" | "styles";
  showWidgetPanel: boolean;
  showSettingsPanel: boolean;
};

export type WidgetState = "add-widgets" | "view-layers";
