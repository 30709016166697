import type { CellLayout, DashboardView } from "@fscrypto/domain/dashboard";
import clsx from "clsx";
import type React from "react";
import { memo } from "react";
import type { ReactNode } from "react";
import { useActiveCellListener } from "../../hooks/useActiveCellListener";
import { useDashboardCellLayout } from "../../hooks/useDashboardCellLayout";
import { CellLabel, CellOptions } from "./components";

/**
 * ActiveCellListener component
 *
 * This component wraps dashboard cells and provides active cell functionality,
 * including hover effects, borders, and cell options.
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to render within the cell
 * @param {string} props.cellId - Unique identifier for the cell
 * @param {string} props.dashboardId - Identifier for the dashboard
 * @param {DashboardView} props.dashboardView - Current view mode of the dashboard
 * @param {boolean} [props.hideControls=false] - Whether to hide cell controls
 * @param {ReactNode} [props.customOptions] - Custom options to render in cell controls
 */
export const ActiveCellListener = ({
  children,
  cellId,
  dashboardId,
  dashboardView,
  hideControls = false,
  customOptions,
  preventMove = false,
  isMobile = false,
}: {
  children: React.ReactNode;
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  hideControls?: boolean;
  customOptions?: ReactNode;
  preventMove?: boolean;
  isMobile?: boolean;
}) => {
  const { isActiveCell, isActiveLayout, handleClick, showHoverMask, showAncestorBorder, cell } = useActiveCellListener(
    cellId,
    dashboardId,
    dashboardView,
  );
  const { layout, siblingLayouts } = useDashboardCellLayout(cellId, dashboardId, dashboardView, isMobile);

  const paddingClasses = getPaddingClasses(layout, siblingLayouts ?? []);
  return (
    <div className={clsx("size-full flex flex-1 flex-col", paddingClasses)}>
      <div
        data-cell-id={cellId}
        className={clsx("size-full relative flex flex-1 flex-col", {
          // Apply different border styles based on cell state and dashboard view
          "border-primary rounded border-dashed border": showAncestorBorder,
          "border-primary rounded border border-dashed": (isActiveCell || isActiveLayout) && dashboardView === "draft",
          "border-transparent border":
            !((isActiveCell || isActiveLayout) && dashboardView === "draft") && !showAncestorBorder,
        })}
        onClick={handleClick}
      >
        {/* Render hover mask when cell is being hovered */}
        {showHoverMask && (
          <div className="size-full bg-primary absolute inset-0 z-20 rounded bg-opacity-10 pointer-events-none">
            <CellLabel cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
          </div>
        )}
        {/* Render children components with memoization for performance */}
        <MemoizedChildren>{children}</MemoizedChildren>
        {/* Render cell options when cell is active and controls are not hidden */}
        {!hideControls && (
          <CellOptions
            cellId={cellId}
            dashboardId={dashboardId}
            dashboardView={dashboardView}
            isActiveCell={isActiveCell}
            parentCellId={cell.parentCellId ?? "root"}
            preventMove={preventMove}
          >
            {customOptions ?? null}
          </CellOptions>
        )}
      </div>
    </div>
  );
};

/**
 * MemoizedChildren component
 *
 * This component wraps children elements and memoizes them to prevent
 * unnecessary re-renders when the ActiveCellListener updates.
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to memoize
 */
const MemoizedChildren = memo(({ children }: { children: React.ReactNode }) => <>{children}</>);

MemoizedChildren.displayName = "MemoizedChildren";

const getPaddingClasses = (layout: CellLayout | undefined, siblingLayouts: CellLayout[]) => {
  if (!layout) return {}; // Default padding if layout is not available

  const { x, y, w, h } = layout;
  const isLeftEdge = x === 0;
  const isTopEdge = y === 0;
  const isRightEdge = x + w === 12; // Assuming 12-column grid
  const isBottomEdge = !siblingLayouts.some((sibling) => sibling.y >= y + h);

  return {
    "pl-0": isLeftEdge,
    "pl-1": !isLeftEdge,
    "pt-0": isTopEdge,
    "pt-1": !isTopEdge,
    "pr-0": isRightEdge,
    "pr-1": !isRightEdge,
    "pb-0": isBottomEdge,
    "pb-1": !isBottomEdge,
  };
};
