import { DashboardView } from "@fscrypto/domain/dashboard";
import { dashboard as dashboardDomain } from "@fscrypto/domain/dashboard";
import { useEntity, useStoreWithDynamicSelector } from "@fscrypto/state-management";
import { useEffect } from "react";
import { useQueries } from "../../../query/hooks/useQueries";
import { dashboardFactory } from "../../state/dashboard";
import { dashboardDataManager } from "../../state/dashboard-data";
import { useDashboardCell } from "../useDashboardCell";

/**
 * Interface for the props of useMergedParamsFromChildQueries hook
 */
interface UseMergedParamsFromChildQueriesProps {
  dashboardId: string;
  paramCellId: string;
  dashboardView: DashboardView;
}

/**
 * A custom hook that merges parameters from child queries of a parameter cell.
 *
 * This hook performs the following operations:
 * 1. Retrieves the dashboard entity and the specific cell.
 * 2. Finds all child query IDs deep within the cell's layout.
 * 3. Fetches data for all these queries.
 * 4. Merges the parameters from all child queries.
 *
 * @param {UseMergedParamsFromChildQueriesProps} props - The props for the hook.
 * @returns {Object} An object containing the merged parameters and child query IDs.
 */
export const useMergedParamsFromChildQueries = ({
  dashboardId,
  paramCellId,
  dashboardView,
}: UseMergedParamsFromChildQueriesProps) => {
  const db = useEntity(dashboardFactory, dashboardId);

  const { cell } = useDashboardCell(paramCellId, dashboardId, dashboardView);
  const layoutId = cell.parentCellId ?? "root";
  const queryIds = useStoreWithDynamicSelector(db?.store, (s) => {
    const config = dashboardView === "published" ? s.publishedConfig : s.draftConfig;
    return dashboardDomain.findDeepChildQueryIds(layoutId)(config);
  });

  // Fetch data for all child queries whenever queryIds change
  useEffect(() => {
    if (!queryIds) return;
    dashboardDataManager.getMultipleQueryData(queryIds);
  }, [queryIds, dashboardId, dashboardView]);

  // Get the actual query data for all child queries
  const queries = useQueries(queryIds ?? []);

  // Merge parameters from all child queries
  const mergedParameters = dashboardDomain.mergeQueryParameters(queries);
  return { mergedParameters, childQueryIds: queryIds };
};

interface UseMergedParamsFromLayoutProps {
  dashboardId: string;
  layoutId: string;
  dashboardView: DashboardView;
}

/**
 * A custom hook that merges parameters from child queries of a layout.
 *
 * This hook performs the following operations:
 * 1. Retrieves the dashboard entity.
 * 2. Finds all child query IDs deep within the specified layout.
 * 3. Fetches data for all these queries.
 * 4. Merges the parameters from all child queries.
 *
 * @param {UseMergedParamsFromLayoutProps} props - The props for the hook.
 * @returns {Object} An object containing the merged parameters and child query IDs.
 */

export const useMergedParamsFromLayout = ({ dashboardId, layoutId, dashboardView }: UseMergedParamsFromLayoutProps) => {
  const db = useEntity(dashboardFactory, dashboardId);
  const queryIds = useStoreWithDynamicSelector(db?.store, (s) => {
    const config = dashboardView === "published" ? s.publishedConfig : s.draftConfig;
    return dashboardDomain.findDeepChildQueryIds(layoutId)(config) ?? [];
  });

  // Fetch data for all child queries whenever queryIds change
  useEffect(() => {
    if (!queryIds) return;
    dashboardDataManager.getMultipleQueryData(queryIds);
  }, [queryIds, dashboardId, dashboardView]);

  // Get the actual query data for all child queries
  const queries = useQueries(queryIds ?? []);

  // Merge parameters from all child queries
  const mergedParameters = dashboardDomain.mergeQueryParameters(queries);
  return { mergedParameters, childQueryIds: queryIds };
};
