import type { BaseCellStyles, DashboardView } from "@fscrypto/domain/dashboard";
import { Text } from "@fscrypto/ui";
import clsx from "clsx";
import type { TextColorStyle } from "node_modules/@fscrypto/domain/src/dashboard/configSchema";
import type { ReactNode } from "react";
import { useDashboardCellContent } from "../../hooks/useDashboardCellContent";
import { useDashboardCellStyles } from "../../hooks/useDashboardCellStyles";
import { DynamicIcon } from "~/shared/components/dynamic-icon";

export const TitleDescription = ({
  cellId,
  dashboardId,
  dashboardView,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const [{ title, description, showDescription, showTitle, icon, iconColor }] = useDashboardCellContent(
    cellId,
    dashboardId,
    "base",
    dashboardView,
  );
  const [cellStyle] = useDashboardCellStyles(cellId, dashboardId, "base", dashboardView);
  if (!showTitle && !showDescription) {
    return null;
  }
  return (
    <div
      className={clsx("flex h-full flex-col px-2", {
        "items-center": !cellStyle?.vertAlignKey || cellStyle?.vertAlignKey === "center",
        "items-start": cellStyle?.vertAlignKey === "start",
        "items-end": cellStyle?.vertAlignKey === "end",
      })}
    >
      <div className="flex items-center gap-x-2">
        {icon && <DynamicIcon name={icon} className="size-5 display-inline" style={{ color: iconColor }} />}
        {showTitle && <Title title={title} titleSize={cellStyle?.titleSize} titleColor={cellStyle?.titleColor} />}
      </div>
      <div className="flex items-center gap-x-2">
        {showDescription && <Description description={description} descriptionColor={cellStyle?.descriptionColor} />}
      </div>
    </div>
  );
};

export const BackgroundContainer = ({
  cellId,
  dashboardId,
  dashboardView,
  children,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  children: ReactNode;
}) => {
  const [{ background }] = useDashboardCellStyles(cellId, dashboardId, "base", dashboardView);
  const { type, appColor, color1, color2, bgUrl } = background ?? {};
  const backgroundStyle = (() => {
    // if (!color1 || !color2) return { backgroundColor: !appColor ? color1 : undefined };

    switch (type) {
      case "gr-horiz":
        return { background: `linear-gradient(to right, ${color1}, ${color2})` };
      case "gr-vert":
        return { background: `linear-gradient(to bottom, ${color1}, ${color2})` };
      case "gr-radial":
        return { background: `radial-gradient(circle, ${color1}, ${color2})` };
      case "image":
        return { backgroundImage: bgUrl ? `url(${bgUrl})` : undefined, backgroundSize: "cover" };
      default:
        return { backgroundColor: !appColor ? color1 : undefined };
    }
  })();
  return (
    <div
      className={clsx("flex h-full w-full flex-1 flex-col", {
        "bg-primary": type === "solid" && appColor === "bg-primary",
        "bg-muted": type === "solid" && appColor === "bg-muted",
        "bg-success": type === "solid" && appColor === "bg-success",
        "bg-warning": type === "solid" && appColor === "bg-warning",
        "bg-background": type === "solid" && appColor === "bg-background",
      })}
      style={backgroundStyle}
    >
      {children}
    </div>
  );
};

export const DashboardBackgroundContainer = ({
  dashboardId,
  dashboardView,
  children,
}: {
  dashboardId: string;
  dashboardView: DashboardView;
  children: ReactNode;
}) => {
  const [{ dashboardBackground }] = useDashboardCellStyles("root", dashboardId, "root", dashboardView);
  const { type = "solid", appColor, color1, color2, bgUrl } = dashboardBackground ?? {};
  const backgroundStyle = (() => {
    switch (type) {
      case "gr-horiz":
        return { background: `linear-gradient(to right, ${color1}, ${color2})` };
      case "gr-vert":
        return { background: `linear-gradient(to bottom, ${color1}, ${color2})` };
      case "gr-radial":
        return { background: `radial-gradient(circle, ${color1}, ${color2})` };
      case "image":
        return { backgroundImage: bgUrl ? `url(${bgUrl})` : undefined, backgroundSize: "cover" };
      default:
        return { backgroundColor: !appColor ? color1 : undefined };
    }
  })();
  return (
    <div
      className={clsx("flex h-full w-full flex-1 flex-col", {
        "bg-primary": type === "solid" && appColor === "bg-primary",
        "bg-muted": type === "solid" && appColor === "bg-muted",
        "bg-success": type === "solid" && appColor === "bg-success",
        "bg-warning": type === "solid" && appColor === "bg-warning",
        "bg-background": type === "solid" && appColor === "bg-background",
      })}
      style={backgroundStyle}
    >
      {children}
    </div>
  );
};

export const BorderContainer = ({
  cellId,
  dashboardId,
  dashboardView,
  children,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  children: ReactNode;
}) => {
  const [{ border, shadow }] = useDashboardCellStyles(cellId, dashboardId, "base", dashboardView);
  return (
    <div
      className={clsx("size-full flex flex-1 flex-col overflow-hidden", {
        "shadow-2xl": shadow === "2xl",
        "shadow-xl": shadow === "xl",
        "shadow-lg": shadow === "lg",
        "shadow-md": shadow === "md",
        "shadow-sm": shadow === "sm",
        "shadow-none": shadow === "none",
        "overflow-hidden rounded-md border": !border,
      })}
      style={{
        border:
          !!border && border?.style !== "none"
            ? `${border?.width ?? 0}px ${border?.style ?? "solid"} ${border?.color ?? "#ccc"}`
            : undefined,
        borderRadius: border ? `${border?.radius ?? 0}px` : undefined,
      }}
    >
      {children}
    </div>
  );
};

export const Title = ({
  titleSize,
  titleColor,
  title,
}: {
  titleSize?: BaseCellStyles["titleSize"];
  titleColor?: TextColorStyle;
  title?: string;
}) => {
  const { appColor, color1 } = titleColor ?? {};
  return (
    <Text
      size={titleSize ?? "lg"}
      weight="semi"
      className={clsx("h-full w-full truncate", {
        "text-primary": appColor === "app-primary",
        "text-muted": appColor === "app-muted",
        "text-success": appColor === "app-success",
        "text-warning": appColor === "app-warning",
        "text-background": appColor === "app-bg",
        "text-foreground": !titleColor || appColor === "app-foreground",
      })}
      style={{ color: !appColor ? color1 : undefined }}
    >
      {title}
    </Text>
  );
};

export const Description = ({
  description,
  descriptionColor,
}: {
  description?: string;
  descriptionColor?: TextColorStyle;
}) => {
  const { appColor, color1 } = descriptionColor ?? {};
  return (
    <Text
      size={"sm"}
      className={clsx("h-full w-full", {
        "text-primary": appColor === "app-primary",
        "text-muted-foreground": !descriptionColor || appColor === "app-muted",
        "text-success": appColor === "app-success",
        "text-warning": appColor === "app-warning",
        "text-background": appColor === "app-bg",
        "text-foreground": appColor === "app-foreground",
      })}
      style={{ color: !appColor ? color1 : undefined }}
    >
      {description}
    </Text>
  );
};
